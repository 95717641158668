<template>
  <div>
    <CCollapse :show="activeItem(1)">
        <Recepcion/>
    </CCollapse>
    <CCollapse :show="activeItem(2)">
        <Dispatch/>
    </CCollapse>
    <CCollapse :show="activeItem(3)">
        <Inventary/>
    </CCollapse>
    <CCollapse :show="activeItem(4)">
        <DispatchExport/>
    </CCollapse>
    <CCollapse :show="activeItem(5)">
        <Embarked/>
    </CCollapse>
    <CCollapse :show="activeItem(6)">
        <Canceled/>
    </CCollapse>
    <CCollapse :show="activeItem(7)">
        <StateYard/>
    </CCollapse>
    <CCollapse :show="activeItem(8)">
        <Event/>
    </CCollapse>
    <CCollapse :show="activeItem(9)">
        <Inspection />
    </CCollapse>
  </div>
</template>
<script>
  import { mapState } from 'vuex';
  import Recepcion from './recepcion-list';
  import Dispatch from './dispatch-list';
  import Inventary from './inventary-list';
  import DispatchExport from './dispatch-export-list';
  import Embarked from './embarked-export-list';
  import Canceled from './canceled-export-list';
  import StateYard from './state-yard-export-list';
  import Event from './event-list';
  import Inspection from './inspection-list';


  //methods
  function activeItem(item) {
    return this.dropItemReport === item;
  }

  export default{
      name: 'reports-index',
      methods:{
            activeItem,
        },
        components:{
          StateYard,
          Dispatch,
          DispatchExport,
          Canceled,
          Embarked,
          Inventary,
          Recepcion,
          Event,
          Inspection
        },
        computed:{
            ...mapState({
                dropItemReport: state => state.yardManagement.dropItemReport,
            })
        },
        watch:{
            //tabIndex
        }
  }
</script>