var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-3"},[_c('AlertModal',{attrs:{"modal":_vm.alertModal,"staffList":_vm.staffFile,"fileName":_vm.fileName,"type":1,"description":_vm.description},on:{"update:modal":function($event){_vm.alertModal=$event}}}),_c('CRow',{staticClass:"mb-3"},[_c('CCol',{staticClass:"d-flex align-items-center justify-content-start",attrs:{"sm":"12","lg":"12","xl":"12"}},[_c('h6',{staticClass:"mt-2"},[_c('CIcon',{staticClass:"titulo-icono",attrs:{"name":"cil-list","size":"lg"}}),_vm._v(" "+_vm._s(`${this.$t('label.massiveListOfEquipmentReception')}`)+" ")],1)])],1),_c('CRow',[_c('CCol',{attrs:{"sm":"6"}},[_c('CSelect',{attrs:{"label":_vm.$t('label.visit'),"horizontal":{
                    label: 'col-sm-12 col-lg-4 text-right',
                    input: 'col-sm-12 col-lg-8',
                },"addLabelClasses":"required"}})],1),_c('CCol',{attrs:{"sm":"1"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({content: _vm.$t('label.DownloadFormat'),placement: 'top'}),expression:"{content: $t('label.DownloadFormat'),placement: 'top'}"}],attrs:{"color":"excel","size":"sm","href":this.$store.getters['connection/getBase']+'download/FormatoMigracionVehiculos.xlsx'}},[_c('CIcon',{attrs:{"name":"cil-file-excel"}})],1)],1)],1),_c('br'),_c('CTabs',{attrs:{"variant":"tabs","active-tab":0}},[_c('CTab',{attrs:{"title":_vm.$t('label.UploadFile')}},[_c('CCard',{staticClass:"form-especial",staticStyle:{"padding":"5px"},attrs:{"bodyWrapper":""}},[_c('CRow',{staticClass:"mb-3"},[_c('CCol',{staticClass:"mb-3",attrs:{"sm":"12"}},[_c('vue-dropzone',{ref:"myVueDropzone",staticClass:"col-lg-12",attrs:{"id":"dropzone","options":_vm.dropzoneOptions,"useCustomSlot":true},on:{"vdropzone-files-added":_vm.sendingEvent,"vdropzone-removed-file":_vm.deleteFile,"vdropzone-error":_vm.DropzoneError}},[_c('div',[_c('h3',{staticClass:"dropzone-custom-title"},[_c('CIcon',{attrs:{"name":"cil-cloud-upload"}}),_vm._v(" ¡"+_vm._s(_vm.$t('label.dragAndDropToUploadContent'))+"!")],1),_c('div',[_vm._v("..."+_vm._s(_vm.$t('label.orClickToSelectFileFromYourComputer'))+" ")])])])],1),_c('CCol',{attrs:{"sm":"11"}},[_c('CInput',{attrs:{"label":_vm.$t('label.description'),"horizontal":"","addLabelClasses":"required text-right","placeholder":_vm.$t('label.documentDescription')},scopedSlots:_vm._u([{key:"invalid-feedback",fn:function(){return undefined},proxy:true}])})],1),_c('CCol',{staticClass:"d-flex align-items-start justify-content-end",attrs:{"sm":"1"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                                content: _vm.$t('label.add'),
                                placement: 'top-end'
                            }),expression:"{\n                                content: $t('label.add'),\n                                placement: 'top-end'\n                            }"}],attrs:{"color":"add","size":"sm"}},[_c('CIcon',{attrs:{"name":"checkAlt"}})],1)],1)],1)],1)],1)],1),_c('CRow',{staticClass:"mt-2"},[_c('CCol',{attrs:{"sm":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"items":_vm.computedListContainer,"fields":_vm.fields,"items-per-page":5,"column-filter":"","pagination":"","table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"noItemsView":_vm.tableText.noItemsViewText,"loading":_vm.loading,"sorter":""},scopedSlots:_vm._u([{key:"options",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                          content: _vm.$t('label.ViewContainer'),
                          placement: 'top-end'
                      }),expression:"{\n                          content: $t('label.ViewContainer'),\n                          placement: 'top-end'\n                      }"}],staticClass:"d-flex align-items-center",attrs:{"square":"","size":"sm","color":"watch"},on:{"click":function($event){return _vm.toggleContainer(item)}}},[_c('CIcon',{attrs:{"name":"cil-list"}})],1)],1)]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }