<template>
    <div>
        <CCollapse :show="activeItem(1)">
            <MasiveLoad/>
        </CCollapse>
        <CCollapse :show="activeItem(2)">
            <Reports/>
        </CCollapse>
        <CCollapse :show="activeItem(3)">
            <DinamicReport/>
        </CCollapse>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import DinamicReport from './dinamic-report';
    import MasiveLoad from './masive-load';
    import Reports from './reports';

    //methods
    function activeItem(item) {
        return this.dropItemInventary === item;
    }

    export default {
        name:"inventary-index",
        methods:{   
            activeItem
        },
        components:{
            DinamicReport,
            MasiveLoad,
            Reports
        },
        computed:{
            ...mapState({
                dropItemInventary: state => state.yardManagement.dropItemInventary,
            })
        },
        watch:{
            //tabIndex
        }
    }
</script>