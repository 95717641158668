<template>
  <div>
    <CRow class="mb-3">
      <CCol sm="8" lg="8" xl="8" class="d-flex align-items-center justify-content-start">
          <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" />{{ `${$t('label.dispatchList')}(EXPORT)`}}</h6>
      </CCol>
      <CCol sm="4" lg="4" xl="4" class="d-flex align-items-center justify-content-end">
          <CButton 
              color="danger"
              class="mr-1"
              size="sm" 
              @click="onBtnPdf()"
              >
              <CIcon name="cil-file-pdf"/>&nbsp; PDF 
          </CButton>
          <CButton
              color="excel"
              class="mr-1" 
              size="sm"
              @click="onBtnExport(true)"
          >
              <CIcon name="cil-file-excel"/>&nbsp; XSLX
          </CButton>
          <CButton
              color="watch"
              size="sm"  
              @click="onBtnExport(false)"
          >
              <CIcon name="cil-file-excel"/>&nbsp; CSV
          </CButton>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12" lg="2" xl="3">
        <CSelect
          size="sm"
          :horizontal="{ label: 'col-sm-12 col-lg-3 text-right', input: 'col-sm-12 col-lg-9'}"
          :label="$t('label.yard')"
        ></CSelect>
      </CCol>
      <CCol sm="12" lg="6">
        <div class="form-group form-row mb-2">
          <label class="col-form-label-sm col-sm-12 col-lg-1 text-right">{{`${$t('label.date')}`}}</label>
          <div class="input-group col-sm-5 col-lg-4">
            <vue-datepicker 
              type="datetime"
              header
              :lang="this.$i18n.locale"
              :editable="false"
              :clearable="false"
              format="DD/MM/YYYY HH:mm"
              placeholder="DD/MM/YYYY HH:mm"
              time-title-format="DD/MM/YYYY HH:mm"
              v-model="TransactionDate"
              :disabled-date="validateDateRange"
              :append-to-body="false"
              value-type="format"
              :show-second="false"
              class="w-100"
            >
              <template #input>
                <CInput
                  v-uppercase
                  class="w-100 float-left"
                  :value="TransactionDate"
                  placeholder="DD/MM/YYYY HH:mm"
                  :is-valid="true"
                  @blur="true"
                  size="sm"                      
                >
                  <template #append-content>
                    <CIcon name="cil-calendar" />
                  </template>
                </CInput>
              </template>
              <template #icon-calendar>
                <div style="display: none"></div>
              </template>
            </vue-datepicker>
          </div>

          <div class="input-group col-sm-5 col-lg-4">
            <vue-datepicker 
              type="datetime"
              header
              :lang="this.$i18n.locale"
              :editable="false"
              :clearable="false"
              format="DD/MM/YYYY HH:mm"
              placeholder="DD/MM/YYYY HH:mm"
              time-title-format="DD/MM/YYYY HH:mm"
              v-model="TransactionDate"
              :disabled-date="validateDateRange"
              :append-to-body="false"
              value-type="format"
              :show-second="false"
              class="w-100"
            >
              <template #input>
                <CInput
                  v-uppercase
                  class="w-100 float-left"
                  :value="TransactionDate"
                  placeholder="DD/MM/YYYY HH:mm"
                  :is-valid="true"
                  @blur="true"
                  size="sm"                      
                >
                  <template #append-content>
                    <CIcon name="cil-calendar" />
                  </template>
                </CInput>
              </template>
              <template #icon-calendar>
                <div style="display: none"></div>
              </template>
            </vue-datepicker>
          </div>
          <div class="d-flex align-items-start col-sm-2 col-lg-2">
            <CButton
                color="watch"
                square
                size="sm"
                class="mx-1"
                style="padding: 0.15rem 0.4rem;"
                v-c-tooltip="{
                    content: $t(''),
                    placement: 'top-end'
                }"
            >
              <CIcon name="cil-search"/>
            </CButton>
            <CButton
                color="danger"
                square
                size="sm"
                style="padding: 0.15rem 0.4rem;"
                v-c-tooltip="{
                    content: $t(''),
                    placement: 'top-end'
                }"
            >
              <CIcon name="cil-brush-alt"/>
            </CButton>
          </div>
        </div>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12" lg="3" xl="3">
          <CInput
            size="sm"
            :label="$t('label.filter')"
            :horizontal="{ label: 'col-sm-12 col-lg-3 text-right', input: 'col-sm-12 col-lg-9'}"
            :placeholder="$t('label.enterSearch')" 
            @keyup="onQuickFilterChanged($event.target.value)"         
          >
          </CInput>  
      </CCol>
      <CCol sm="12" lg="6">                                                                                                               
          <CSelect
            size="sm"
            :horizontal="{ label: 'col-sm-12 col-lg-1 text-right', input: 'col-sm-12 col-lg-8'}"
            :label="$t('label.visit')"
          ></CSelect>
      </CCol>
      <CCol sm="12" lg="3" class="justify-content-end">
          <CSelect
            size="sm"
            :horizontal="{ label: 'col-sm-12 col-lg-8 col-xl-8 text-right', input: 'col-sm-12 col-lg-4 col-xl-4'}"
            :label="$t('label.recordsPerPage')"
            @change="onPageSizeChanged"
            :options="[50,100,500,1000]"
          >
          </CSelect>
      </CCol>
  </CRow>
  <CRow>
    <CCol col="12" xl="12">
      
    </CCol>
  </CRow>
  <CRow>
    <CCol sm="11" lg="12" xl="12" class="mb-2">
        <div class="d-flex bd-highlight">
            <div class="bd-highlight" style="width:50px">
                <CButton
                    block
                    style="height: 100vh;"
                    shape="square"
                    color="light"
                    @click="ModalColumn = true"
                >
                    <div id="vertical-orientation" class="d-flex align-items-center border-right justify-content-center">
                        <CIcon name="cil-cog" class="correct-vertical"/>
                    </div>
                </CButton>
            </div>
            <div class="flex-grow-1 bd-highlight">
                <ag-grid-vue
                  style="width: 100%; height: 100vh;"
                  class="ag-theme-alpine"
                  :getRowClass="getRowClass"
                  :gridOptions="gridOptions"
                  :defaultColDef="defaultColDef"
                  :localeText="localeText"
                  :columnDefs="columnDefs"
                  :rowData="RowData"
                  :suppressRowClickSelection="true"
                  :groupSelectsChildren="true"
                  :enableRangeSelection="true"
                  :pagination="true"
                  :paginationPageSize="paginationPageSize"
                  :paginationNumberFormatter="paginationNumberFormatter"
                  @grid-ready="onGridReady"
              >
              </ag-grid-vue>
            </div>
        </div>
    </CCol>
  </CRow>
  <ModalRegister
    :modal.sync="ModalColumn"
    :ModalList="columnsOption"
  />
  </div>
</template>
<script>

import GeneralMixin from '@/_mixins/general';
import { localeKey } from '@/_helpers/locale.grid';
import UpperCase from '@/_validations/uppercase-directive';
import { DateFormater } from '@/_helpers/funciones';
import ModalRegister from './modal-columns';

//DATA-------------------------
function data() {
  return {
    gridOptions: null,
    defaultColDef: null,
    paginationPageSize: null,
    paginationNumberFormatter: null,
    gridApi: null,
    columnApi: null,
    DetailJson: [],
    HeaderJson: [],
    ValidPreviousDate: new Date(),
    ValidLaterDate: new Date(),
    previousDate: new Date(),
    laterDate: new Date(),
    IssueDate: '', 
    FinishDate: '',
    TransactionDate: '',
    ModalColumn:false,
    columnsOption:{
      selectAll:true,
      booking:true,
      seal:true,
      dateReception:true,
      number:true,
      eirReception:true,
      broker:true,
      typeOperation:true,
      originVisit:true,
      ata:true,
      countryDay:true,
      dea: true,
      machine: true,
      carrier:true,
      driver: true,
      vehicle: true,
      motiveCancel: true,
      dateCancel: true,
      forAutorized: true,
      visit: true,
      atd: true,
      positionPlanifiqued: true,
      positionExit: true,
      dateEmbarkedConfirmed:true,
      weightSelected: 1,
      UnitMeasureWeigth:[
        {
            value: 1,
            label: 'TON'
        },
        {
            value: 2,
            label: 'KGM'
        }
      ],
    },
  }
}

//beforeMount
function beforeMount() {
    this.gridOptions = {};
    this.defaultColDef = {
        editable: false,
        resizable: true,
        sortable: true,
        minWidth: 150,
        flex: 1,
    };
    this.paginationPageSize = 50;
    this.paginationNumberFormatter = (params) => {
        return params.value.toLocaleString();
    };
}

//methods
function onGridReady(params) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
}

function onQuickFilterChanged(value) {
    this.gridApi.setQuickFilter(value);
}

function onPageSizeChanged(event) {
    this.gridApi.paginationSetPageSize(parseInt(event.target.value));
}

//computed
function RowData(){
  return []
}

function localeText(){
    let AG_GRID_LOCALE = {};
    for (let index = 0; index < localeKey.length; index++) {
        const element = localeKey[index];
        AG_GRID_LOCALE[element] = this.$t('agridTable.'+element);
    }
    return AG_GRID_LOCALE;
}

function columnDefs(){
    let columnDefs = [
        {
            field: "number",
            headerName: '#',
            filter: 'agTextColumnFilter',
            cellClass: 'center-cell',
            lockPosition: true,
            spanHeaderHeight: true,
            minWidth: 100,
            cellClass: 'gb-cell',
            headerClass: 'center-cell-especial',
        },
        {
            field: "cliente",
            headerName: this.$t('label.cargoGroup'),
            filter: 'agTextColumnFilter',
            cellClass: 'center-cell',
            lockPosition: true,
            spanHeaderHeight: true,
            minWidth: 180,
            cellClass: 'gb-cell',
            headerClass: 'center-cell-especial',
        },
        {
            field: "cliente",
            headerName: `${this.$t('label.container')}/${this.$t('label.serial')}`,
            filter: 'agTextColumnFilter',
            cellClass: 'center-cell',
            lockPosition: true,
            spanHeaderHeight: true,
            minWidth: 220,
            cellClass: 'gb-cell',
            headerClass: 'center-cell-especial',
        },
        {
            field: "cliente",
            headerName: this.$t('label.newShippingLine'),
            filter: 'agTextColumnFilter',
            cellClass: 'center-cell',
            lockPosition: true,
            spanHeaderHeight: true,
            minWidth: 180,
            cellClass: 'gb-cell',
            headerClass: 'center-cell-especial',
        },
        {
            field: "cliente",
            headerName: this.$t('label.type'), 
            filter: 'agTextColumnFilter',
            cellClass: 'center-cell',
            lockPosition: true,
            spanHeaderHeight: true,
            minWidth: 180,
        }
      ]

      if(this.columnsOption.booking){
          columnDefs.push({
              field: "",
              headerName: this.$t('label.booking'), 
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              filter: "agTextColumnFilter",
              minWidth: 160,
          });
      }

      if(this.columnsOption.visit){
          columnDefs.push({
              field: "",
              headerName: this.$t('label.visit'), 
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              filter: "agTextColumnFilter",
              minWidth: 160,
          });
      }

      if(this.columnsOption.atd){
          columnDefs.push({
              field: "",
              headerName: this.$t('label.atd'), 
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              filter: "agTextColumnFilter",
              minWidth: 160,
          });
      }

      if(this.columnsOption.seal){
          columnDefs.push({
              field: "",
              headerName: this.$t('label.Seals'), 
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              filter: "agTextColumnFilter",
              minWidth: 160,
          });
      }

      if(this.columnsOption.dateReception){
          columnDefs.push({
              field: "",
              headerName: this.$t('label.receptionDate'), 
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              filter: "agTextColumnFilter",
              minWidth: 200,
          });
      }

      if(this.columnsOption.number){
          columnDefs.push({
              field: "",
              headerName: this.$t('label.UnNumber'), 
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              filter: "agTextColumnFilter",
              minWidth: 160,
          });
      }

      if(this.columnsOption.eirReception){
          columnDefs.push({
              field: "",
              headerName: this.$t('label.receptionEirNo'), 
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              filter: "agTextColumnFilter",
              minWidth: 220,
          });
      }

      if(this.columnsOption.broker){
          columnDefs.push({
              field: "",
              headerName: this.$t('label.customBroker'), 
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              filter: "agTextColumnFilter",
              minWidth: 200,
          });
      }

      if(this.columnsOption.typeOperation){
          columnDefs.push({
              field: "",
              headerName: this.$t('label.customsRegime'), 
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              filter: "agTextColumnFilter",
              minWidth: 200,
          });
      }

      if(this.columnsOption.originVisit){
          columnDefs.push({
              field: "",
              headerName: this.$t('label.originVisit'), 
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              filter: "agTextColumnFilter",
              minWidth: 160,
          });
      }

      if(this.columnsOption.ata){
          columnDefs.push({
              field: "",
              headerName: "ATA", 
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              filter: "agTextColumnFilter",
              minWidth: 160,
          });
      }

      if(this.columnsOption.countryDay){
          columnDefs.push({
              field: "",
              headerName: this.$t('label.DaysInCountry'), 
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              filter: "agTextColumnFilter",
              minWidth: 200,
          });
      }

      if(this.columnsOption.dea){
          columnDefs.push({
              field: "",
              headerName: "DEA", 
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              filter: "agTextColumnFilter",
              minWidth: 160,
          });
      }

      if(this.columnsOption.machine){
          columnDefs.push({
              field: "",
              headerName: this.$t('label.machine'), 
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              filter: "agTextColumnFilter",
              minWidth: 160,
          });
      }


      if(this.columnsOption.carrier){
          columnDefs.push({
              field: "",
              headerName: this.$t('label.transport'), 
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              filter: "agTextColumnFilter",
              minWidth: 160,
          });
      }
      if(this.columnsOption.driver){
          columnDefs.push({
              field: "",
              headerName: this.$t('label.driver'), 
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              filter: "agTextColumnFilter",
              minWidth: 160,
          });
      }

      if(this.columnsOption.vehicle){
          columnDefs.push({
              field: "",
              headerName: this.$t('label.vehicle'), 
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              filter: "agTextColumnFilter",
              minWidth: 160,
          });
      }

      if(this.columnsOption.positionPlanifiqued){
          columnDefs.push({
              field: "",
              headerName: this.$t('label.plannedPosition'), 
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              filter: "agTextColumnFilter",
              minWidth: 220,
          });
      }

      if(this.columnsOption.positionExit){
          columnDefs.push({
              field: "",
              headerName: this.$t('label.DEPARTURE_POSITION'), 
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              filter: "agTextColumnFilter",
              minWidth: 220,
          });
      }

      if(this.columnsOption.dateEmbarkedConfirmed){
          columnDefs.push({
              field: "",
              headerName: this.$t('label.shipmentConfirmationDate'), 
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              filter: "agTextColumnFilter",
              minWidth: 280,
          });
      }

      if(this.columnsOption.motiveCancel){
          columnDefs.push({
              field: "",
              headerName: this.$t('label.CancellationReasons'), 
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              filter: "agTextColumnFilter",
              minWidth: 220,
          });
      }
      if(this.columnsOption.dateCancel){
          columnDefs.push({
              field: "",
              headerName: this.$t('label.cancelationDate'), 
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              filter: "agTextColumnFilter",
              minWidth: 220,
          });
      }

      if(this.columnsOption.forAutorized){
          columnDefs.push({
              field: "",
              headerName: this.$t('label.AUTHORIZED_BY'), 
              headerClass: 'center-cell-especial',
              cellClass: 'center-cell-especial',
              filter: "agTextColumnFilter",
              minWidth: 200,
          });
      }
      
    return columnDefs;
}

async function onBtnPdf(valor){
}

async function onBtnExport(valor){
}

function validateDateRange(date) {
  return date < this.previousDate || date > this.laterDate;
}

function getRowClass(params) {
  return 'bg-confirmed'
}

export default {
  name: 'dispatch-export-list',
  components: {
    ModalRegister
   },
  data,
  beforeMount,
  mixins: [GeneralMixin],
  methods: {
    onGridReady,
    onQuickFilterChanged,
    onPageSizeChanged,
    onBtnPdf,
    onBtnExport,
    validateDateRange,
    getRowClass
  },
  computed: {
    RowData,
    columnDefs,
    localeText
  },
  directives: UpperCase,
  watch: {
    TransactionDate: async function(val){
      try{
        if(val != ''){
          if (DateFormater.formatDateTimeWithoutSlash(val) < this.ValidPreviousDate ) {
            throw this.$t('label.ErrorAtaPleaseCheck');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) < this.ValidPreviousDate ) {
            throw this.$t('label.ErrorEtaPleaseCheck');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) > this.ValidLaterDate ) {
            throw this.$t('label.ErrorAtdPleaseCheck');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) > this.ValidLaterDate ) {
            throw this.$t('label.ErrorCurrentDatedPleaseCheck');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) < this.IssueDate) {
              throw this.$t('label.ErrorOrderDateIssuePleaseCheck');
          }
          if (this.FinishDate != this.ValidLaterDate && DateFormater.formatDateTimeWithoutSlash(val) > this.FinishDate) {
              throw this.$t('label.ErrorOrderDateFinishPleaseCheck');
          }
        }
      }catch(e){
        this.notifyError({text: e});
      }
    },
  },
};
</script>
<style>
  .Entry-state-yard-header{
      background: #1f3864 !important;
  }
  .Entry-state-yard-header .ag-header-group-text{
      width: 100%;
  }
  .Status-state-yard-header{
      background: #ed7d31 !important;
  }
  .Status-state-yard-header .ag-header-group-text{
      width: 100%;
  }
  .Dispatched-state-yard-header{
      background: #616161 !important;
  }
  .Dispatched-state-yard-header .ag-header-group-text{
      width: 100%;
  }
  .Inventary-state-yard-header{
      background: #5797e6 !important;
  }
  .Inventary-state-yard-header .ag-header-group-text{
      width: 100%;
  }
  .center-cell {
      text-align: center;
      vertical-align: middle;
      user-select: initial;
  }
  .ag-header-group-cell-label, .ag-header-cell-label{
      text-align: center;
      justify-content: center;
      align-items: center;
  }
  .center-cell-especial{
          text-align: center;
          vertical-align: middle;
          user-select: initial;
          -moz-user-select: text;
          -ms-user-select: text;
          -webkit-user-select: text;
  }
  .gb-cell{
      background-color: rgba(0, 0, 0, 0.07);
      text-align: center;
      vertical-align: middle;
      user-select: initial;
      -moz-user-select: text;
      -ms-user-select: text;
      -webkit-user-select: text;
  }
</style>